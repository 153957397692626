import { Component, OnInit, OnDestroy, HostListener } from '@angular/core';
import { Subscription } from 'rxjs';
import { SidebarService, ISidebar } from '../sidebar/sidebar.service';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { LangService, Language } from '../../../shared/lang.service';
import { AuthService } from '../../../shared/keycloak.auth.service';
import { environment } from '../../../../environments/environment';
import { getThemeColor, setThemeColor } from '../../../utils/';

@Component({
  selector: 'app-topnav',
  templateUrl: './topnav.component.html',
  styleUrls: ['./topnav.component.css'],
})
export class TopnavComponent implements OnInit, OnDestroy {
  buyUrl = environment.buyUrl;
  adminRoot = environment.adminRoot;
  sidebar: ISidebar;
  subscription: Subscription;
  displayName = 'Sarah Cortney';
  languages: Language[];
  currentLanguage: string;
  isSingleLang: boolean;
  isFullScreen = false;
  isDarkModeActive = false;
  searchKey = '';
  currentUser = null;
  screenWidth: number;
  isSmallScreen: boolean = false;
  public event$;
  public appPath = '';
  public apiPath = '';
  public isVisible: boolean;
  public countEmployers:number;
  public nameSociety:string;
  public currentView:string='';


  constructor(
    private sidebarService: SidebarService,
    private authService: AuthService,
    private router: Router,
    private langService: LangService,
    private route: ActivatedRoute
  ) {
    this.event$ = this.router.events.subscribe(
      (event) => {
        if (event instanceof NavigationEnd) {
          if (event.url.includes('adherent')) {
            this.appPath = 'adherent/';
            this.apiPath = 'mdca';
          } else if (event.url.includes('employeur')) {
            this.appPath = 'employeur/';
            this.apiPath = 'mdce';
          } else {
            this.appPath = '';
            this.apiPath = '';
          }
        }
      }
    );
    this.languages = this.langService.supportedLanguages;
    this.currentLanguage = this.langService.languageShorthand;
    this.isSingleLang = this.langService.isSingleLang;
    this.isDarkModeActive = getThemeColor().indexOf('dark') > -1 ? true : false;
    this.isVisible = this.route.snapshot['_routerState'].url==='/employeur/liste-employeurs' ? false : true
    this.countEmployers = parseInt(localStorage.getItem('countEmployersAllowed')) || null
    this.nameSociety = localStorage.getItem('currentSociety')
    this.isSmallScreen = window.innerWidth < 768;
  }

  onDarkModeChange(event): void {
    let color = getThemeColor();
    if (color.indexOf('dark') > -1) {
      color = color.replace('dark', 'light');
    } else if (color.indexOf('light') > -1) {
      color = color.replace('light', 'dark');
    }
    setThemeColor(color);
    setTimeout(() => {
      window.location.reload();
    }, 200);
  }

  fullScreenClick(): void {
    if (document.fullscreenElement) {
      document.exitFullscreen();
    } else {
      document.documentElement.requestFullscreen();
    }
  }

  @HostListener('document:fullscreenchange', ['$event'])
  handleFullscreen(event: Event): void {
    if (document.fullscreenElement) {
      this.isFullScreen = true;
    } else {
      this.isFullScreen = false;
    }
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: Event) {
    this.isSmallScreen = window.innerWidth < 768;
  }

  onLanguageChange(lang: Language): void {
    this.langService.language = lang.code;
    this.currentLanguage = this.langService.languageShorthand;
  }

  async ngOnInit(): Promise<void> {
    this.currentView = this?.route?.snapshot?.url[0]?.path
    if (JSON.parse(localStorage.getItem('loggedUser'))) {
      const user = JSON.parse(localStorage.getItem('loggedUser'));
      this.currentUser = user;
      this.displayName = user.given_name || user.family_name ? `${user.given_name} ${user.family_name}` : user.email;
    } else {
      this.authService.getLoggerUser().then(
        (user) => {
          this.currentUser = user;
          this.displayName = user.given_name || user.family_name ? `${user.given_name} ${user.family_name}` : user.email;
        }
      );
    }
    this.subscription = this.sidebarService.getSidebar().subscribe(
      (res) => this.sidebar = res,
      (err) => console.error(`An error occurred: ${err.message}`)
    );
    this.screenWidth = window.innerWidth;
    window.onresize = () => {
      this.screenWidth = window.innerWidth;
    };
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
    this.event$.unsubscribe();
  }

  menuButtonClick = (
    e: { stopPropagation: () => void },
    menuClickCount: number,
    containerClassnames: string
  ) => {
    if (e) {
      e.stopPropagation();
    }

    setTimeout(() => {
      const event = document.createEvent('HTMLEvents');
      event.initEvent('resize', false, false);
      window.dispatchEvent(event);
    }, 350);

    this.sidebarService.setContainerClassnames(
      ++menuClickCount,
      containerClassnames,
      this.sidebar.selectedMenuHasSubItems
    );
  };

  mobileMenuButtonClick = (
    event: { stopPropagation: () => void },
    containerClassnames: string
  ) => {
    if (event) {
      event.stopPropagation();
    }
    this.sidebarService.clickOnMobileMenu(containerClassnames);
  };

  onSignOut(): void {
    this.authService.logout();
    this.router.navigate([`/${this.appPath}`]);
  }

  @HostListener('document:click', ['$event'])
  handleDocumentClick(event: MouseEvent): void {
    const input = document.querySelector('.mobile-view');
    if (input && input.classList) {
      input.classList.remove('mobile-view');
    }
    this.searchKey = '';
  }
}
