<nav class="navbar fixed-top">
    <div class="container-xl d-flex flex-lg-row align-items-center justify-content-between">
      <div class="d-flex flex-column flex-lg-row justify-content-center align-items-center mb-3">
        <a (click)="menuButtonClick($event, sidebar.menuClickCount, sidebar.containerClassnames); false;"
          href="javascript:;" class="menu-button d-none d-lg-block d-md-block d-sm-none">
          <svg class="main" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 9 17">
            <rect x="0.48" y="0.5" width="7" height="1" />
            <rect x="0.48" y="7.5" width="7" height="1" />
            <rect x="0.48" y="15.5" width="7" height="1" />
          </svg> {{" "}}
          <svg class="sub" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18 17">
            <rect x="1.56" y="0.5" width="16" height="1" />
            <rect x="1.56" y="7.5" width="16" height="1" />
            <rect x="1.56" y="15.5" width="16" height="1" />
          </svg>
        </a>
    
        <a (click)="mobileMenuButtonClick($event, sidebar.containerClassnames)" href="javascript:;"
          class="menu-button-mobile d-block d-md-none">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 26 17">
            <rect x="0.5" y="0.5" width="25" height="1" />
            <rect x="0.5" y="7.5" width="25" height="1" />
            <rect x="0.5" y="15.5" width="25" height="1" />
          </svg>
        </a>
        
        <div class="d-flex flex-column flex-lg-row justify-content-center">
          <a class="navbar-logo" routerLink="/{{appPath}}{{adminRoot}}">
            <span class="logo" [ngClass]="{
              'd-none d-lg-block': currentView !== 'liste-employeurs',
              'd-block': currentView === 'liste-employeurs'
            }"></span>
          </a>
        </div>
      </div>

      <div class="d-flex flex-column flex-lg-row align-items-center">
        <h3 *ngIf="apiPath==='mdce' && nameSociety && currentView!='liste-employeurs'" 
        class="d-block text-primary text-center" [ngClass]="{'text-truncate': isSmallScreen}" [title]="nameSociety" style="max-width: 200px;">
          {{nameSociety.length > 15 && isSmallScreen ? (nameSociety | slice:0:15) + '...' : nameSociety}}
        </h3>
      </div>
      
      <div class="d-flex flex-row-reverse flex-md-row flex-xl-row mb-3"> 
        <button *ngIf="isVisible && countEmployers>1" class="btn btn-primary mr-1 mt-0 px-md-2 py-md-2 shuffle-btn-circle"
          [routerLink]="['/employeur/liste-employeurs']">
          <span class="d-inline d-md-none">
            <i class="simple-icon-shuffle"></i>
          </span> 
          <span class="d-none d-md-inline">
            <i class="simple-icon-people mr-2"></i>
            Choix de l'employeur
          </span> 
        </button>
        <div dropdown>
          <button class="btn btn-empty mt-0 ml-2" type="button" dropdownToggle>
            <span class="name" [ngClass]="{'text-primary': screenWidth < 540, 'text-white': screenWidth >= 540}">{{displayName}}</span>
            <span class="ml-1">
              <i class="simple-icon-user profile-img-icon"></i>
            </span>
          </button>
    
          <div class="dropdown-menu dropdown-menu-right mt-3 mr-3" *dropdownMenu role="menu">
            <a class="dropdown-item c-pointer" [routerLink]="'mon-compte'" *ngIf="currentUser.roles[0] !== 'employeur'"> Mon Compte </a>
            <a class="dropdown-item c-pointer" [routerLink]="'compte'" *ngIf="currentUser.roles[0] === 'employeur'"> Compte </a>
            <li class="divider dropdown-divider"></li>
            <a class="dropdown-item c-pointer" (click)="onSignOut()">Déconnexion</a>
          </div>
        </div>
      </div>   
    </div> 
</nav>